import axios from "axios";
import hmacSHA512 from 'crypto-js/hmac-sha512';
import Base64 from 'crypto-js/enc-base64';

var CryptoJS = require("crypto-js");
//var ruta_api = "http://localhost";

var ruta_api = "https://erp.agropyrgos.com";
var apigeneral = "/erp-api/";
//var apigeneral = "/dev2/erp-api/";

var x_accesskey = "1081592e5bcf4d20a3233e044a0171f4";
var SecretKey = "116971e670ad4dc6821dd3ea75d7f671";


export async function consumirApi(apis, metodo, json, bandera) {
    var dia_ac = new Date(),
        month = '' + (dia_ac.getUTCMonth() + 1),
        day = '' + dia_ac.getUTCDate(),
        year = dia_ac.getUTCFullYear(),

        h = '' + dia_ac.getUTCHours(),
        m = '' + dia_ac.getUTCMinutes(),
        ss = '' + dia_ac.getUTCSeconds();

    if (month.length < 2) {
        month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }
    if (h.length < 2) {
        h = '0' + h;
    }
    if (m.length < 2) {
        m = '0' + m;
    }
    if (ss.length < 2) {
        ss = '0' + ss;
    }

    let dia_actual = [year, month, day].join('-');
    let hora_actual = [h, m, ss].join(':');
    var fecha = dia_actual + ' ' + hora_actual;
    //console.log(fecha + " " + x_accesskey + " " + apis);

    var Signature = Base64.stringify(hmacSHA512(fecha + x_accesskey + apis, SecretKey));
    //console.log("Bearer " + Signature);

    var headersGenerales = {
        "content-type": "application/json",
        "X-AccessKey": x_accesskey,
        "X-Date": fecha,
        "X-Signature": "Bearer " + Signature,
    };
    if (bandera != 'login') {
        var authorization = localStorage.getItem("ls").split(",")[3];
        headersGenerales = {
            "content-type": "application/json",
            "X-AccessKey": x_accesskey,
            "X-Date": fecha,
            "X-Signature": "Bearer " + Signature,
            "Authorization": authorization
        };
    }

    try {
        var consulta;
        if (metodo == 'get') {
            consulta = await axios.get(ruta_api + apis, {
                headers: headersGenerales,
            });
        }
        else if (metodo == 'post') {
            consulta = await axios.post(ruta_api + apis, json, {
                headers: headersGenerales,
            });
        }

        var resp = consulta.data;
        return resp;
    } catch (error) {
        return -1;
    }

}


export async function login(usuario, password) {
    let jsonfile = { "usuario": usuario, "clave": password };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "login/get-apikey", 'post', jsonfile, 'login');
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}



////////////////////////////////////////
export async function getpruebas() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "app/lista-asistencias", 'get');
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

/////////////////////////////////////////////////////


export async function getReportes(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogoreportes/reportecompras", 'post', json);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

/////////////////////////////////////////////////////


export async function getReportesDetalles(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogoreportes/reportecompras/detalle", 'post', json);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getEstatus() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/generales/estatus", 'get');
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getComboTipoCliente() {

    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogotipo/clientes/lista", 'get');
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
export async function getListaTipoCliente() {

    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogotipo/clientes/catalogo", 'get');
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getCatalogoNominas() {

    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "nominas/catalogo-nomina", 'get');
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
export async function finalizanomina(jsonfile) {

    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "nominas/finalizar-nomina", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
export async function guardardatosNomina(jsonfile) {

    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "nominas/nomina-totales", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaNominas(jsonfile) {

    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "nominas/nomina", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function createNuevaNomina(jsonfile) {

    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "nominas/nueva-nomina", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaTipoClienteUpdate(json) {

    let jsonfile = {
        "idtipo_clientes": json.idtipo_clientes,
        "tipo": json.tipo, "descripcion": json.descripcion, "idestatus": json.idestatus
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogotipo/clientes/guardar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

///////////////////////////////////////////////////////////////////////////////////////////////////
//INICIO Sección APIS para Catálogo Clientes
export async function getListaCatalogoCliente() {

    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/clientes/catalogo", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function getListaCatalogoClienteUpdate(json) {

    let jsonfile = {
        "idcatalogo_clientes": json.idcatalogo_clientes,
        "clave": json.clave, "idtipocliente": json.idtipocliente,
        "nombre": json.nombre, "comentario": json.comentario, "idestatus": json.idestatus
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/clientes/guardar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

///////////////////////////////////////////////////////////////////////////////////////////////////
//INICIO Sección APIS para Ventana Clientes
export async function getListaCatalogoClienteInformacion(json) {

    let jsonfile = {
        "idcatalogo_clientes": json.idcatalogo_clientes
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/clientes/informacion", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaClienteDatosFiscalesUpdate(json) {

    let jsonfile = {
        "idcatalogo_clientes_datosfiscales": json.idcatalogo_clientes_datosfiscales,
        "idcatalogo_clientes": json.idcatalogo_clientes, "nombrerazonsocial": json.nombrerazonsocial,
        "apellidos": json.apellidos, "rfc": json.rfc, "regimenfiscal": json.regimenfiscal,
        "codigopostal": json.codigopostal, "tipovialidad": json.tipovialidad, "nombrevialidad": json.nombrevialidad,
        "numeroexterior": json.numeroexterior, "numerointerior": json.numerointerior,
        "colonia": json.colonia, "localidad": json.localidad, "municipio": json.municipio,
        "estado": json.estado, "entrecalleuno": json.entrecalleuno, "entrecalledos": json.entrecalledos
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/clientes/datosfiscales/guardar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaClienteContactosUpdate(json) {

    let jsonfile = {
        "idcatalogo_clientes_contactos": json.idcatalogo_clientes_contactos,
        "idcatalogo_clientes": json.idcatalogo_clientes,
        "nombre": json.nombre, "puesto": json.puesto,
        "telefono": json.telefono, "email": json.email,
        "principal": json.principal, "comentario": json.comentario,
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/clientes/contacto/guardar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function deleteClienteContacto(json) {

    let jsonfile = {
        "idcatalogo_clientes_contactos": json.idcatalogo_clientes_contactos, "password": json.password
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/clientes/contacto/eliminar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaClienteDireccionUpdate(json) {

    let jsonfile = {
        "idcatalogo_clientes_direcciones": json.idcatalogo_clientes_direcciones, "idcatalogo_clientes": json.idcatalogo_clientes, "calle": json.calle,
        "numeroexterior": json.numeroexterior, "numerointerior": json.numerointerior, "codigopostal": json.codigopostal, "colonia": json.colonia,
        "localidad": json.localidad, "municipio": json.municipio, "estado": json.estado, "pais": json.pais, "referencia": json.referencia, "principal": json.principal
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/clientes/direcciones/guardar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function deleteClienteDireccion(json) {

    let jsonfile = {
        "idcatalogo_clientes_direcciones": json.idcatalogo_clientes_direcciones, "password": json.password
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/clientes/direcciones/eliminar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaClienteDocumentoUpdate(formdata) {

    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/clientes/documentos/guardar", 'post', formdata, "formdata");
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function deleteClienteDocumento(json) {

    let jsonfile = {
        "idcatalogo_clientes_documentos": json.idcatalogo_clientes_documentos, "password": json.password
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/clientes/documentos/eliminar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

///////////////////////////////////////////////////////////////////////////////////////////////////
//INICIO Sección APIS para Ventana Proveedores
export async function getListaCatalogoProveedorInformacion(json) {

    let jsonfile = {
        "idcatalogo_proveedores": json.idcatalogo_proveedores
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/proveedores/informacion", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaProveedorDatosFiscalesUpdate(json) {

    let jsonfile = {
        "idcatalogo_proveedores_datosfiscales": json.idcatalogo_proveedores_datosfiscales,
        "idcatalogo_proveedores": json.idcatalogo_proveedores, "nombrerazonsocial": json.nombrerazonsocial,
        "apellidos": json.apellidos, "rfc": json.rfc, "regimenfiscal": json.regimenfiscal,
        "codigopostal": json.codigopostal, "tipovialidad": json.tipovialidad, "nombrevialidad": json.nombrevialidad,
        "numeroexterior": json.numeroexterior, "numerointerior": json.numerointerior,
        "colonia": json.colonia, "localidad": json.localidad, "municipio": json.municipio,
        "estado": json.estado, "entrecalleuno": json.entrecalleuno, "entrecalledos": json.entrecalledos
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/proveedores/datosfiscales/guardar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaProveedorContactosUpdate(json) {

    let jsonfile = {
        "idcatalogo_proveedores_contactos": json.idcatalogo_proveedores_contactos,
        "idcatalogo_proveedores": json.idcatalogo_proveedores,
        "nombre": json.nombre, "puesto": json.puesto,
        "telefono": json.telefono, "email": json.email,
        "principal": json.principal, "comentario": json.comentario,
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/proveedores/contacto/guardar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function deleteProveedorContacto(json) {

    let jsonfile = {
        "idcatalogo_proveedores_contactos": json.idcatalogo_proveedores_contactos, "password": json.password
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/proveedores/contacto/eliminar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaProveedorDireccionUpdate(json) {

    let jsonfile = {
        "idcatalogo_proveedores_direcciones": json.idcatalogo_proveedores_direcciones, "idcatalogo_proveedores": json.idcatalogo_proveedores, "calle": json.calle,
        "numeroexterior": json.numeroexterior, "numerointerior": json.numerointerior, "codigopostal": json.codigopostal, "colonia": json.colonia,
        "localidad": json.localidad, "municipio": json.municipio, "estado": json.estado, "pais": json.pais, "referencia": json.referencia, "principal": json.principal
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/proveedores/direcciones/guardar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function deleteProveedorDireccion(json) {

    let jsonfile = {
        "idcatalogo_proveedores_direcciones": json.idcatalogo_proveedores_direcciones, "password": json.password
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/proveedores/direcciones/eliminar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaProveedorDocumentoUpdate(formdata) {

    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/proveedores/documentos/guardar", 'post', formdata, "formdata");
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function deleteProveedorDocumento(json) {

    let jsonfile = {
        "idcatalogo_proveedores_documentos": json.idcatalogo_proveedores_documentos, "password": json.password
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/proveedores/documentos/eliminar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaCatalogoTipoDocumento() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogotipo/documentos/lista", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaCatalogoDocumentos() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/documentos/lista", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


///////////////////////////////////////////////////////////////////////////////////////////////////
//INICIO Sección APIS para Catálogo Tipo Documentos

export async function getComboTipoDocumentos() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogotipo/documentos/lista", 'get');
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaTipoDocumentos() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogotipo/documentos/catalogo", 'get');
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function getListaTipoDocumentosUpdate(json) {
    let jsonfile = {
        "idtipo_documento": json.idtipo_documento,
        "tipo": json.tipo,
        "descripcion": json.descripcion,
        "idestatus": json.idestatus
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogotipo/documentos/guardar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
///////////////////////////////////////////////////////////////////////////////////////////////////
//FIN Sección APIS para Catálogo Tipo Documentos

export async function getComboTipoProveedor() {

    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogotipo/proveedores/lista", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function getListaTipoProveedor() {

    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogotipo/proveedores/catalogo", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaTipoProveedorUpdate(json) {

    let jsonfile = {
        "idtipo_proveedor": json.idtipo_proveedor,
        "tipo": json.tipo, "descripcion": json.descripcion, "idestatus": json.idestatus
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogotipo/proveedores/guardar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function getListaTipoUsuario() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogotipo/usuarios/catalogo", 'get');
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }

    return resp;
}


export async function getListaTipoUsuarioUpdate(json) {
    let jsonfile = {
        "idtipo_usuario": json.idtipo_usuario,
        "tipo": json.tipo, "descripcion": json.descripcion, "idestatus": json.idestatus
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogotipo/usuarios/guardar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}



///////////////////////////////////////////////////////////////////////////////////////////////////
//INICIO Sección APIS para Familia Documentos

export async function getListaFamiliaDocumentos() {

    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogofamilia/documentos/catalogo", 'get');
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function getListaFamiliaDocumentosUpdate(json) {
    let jsonfile = {
        "idfamilia_documentos": json.idfamilia_documentos,
        "familia": json.familia, "descripcion": json.descripcion, "idestatus": json.idestatus
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogofamilia/documentos/guardar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
///////////////////////////////////////////////////////////////////////////////////////////////////
//FIN Sección APIS para Familia Documentos


export async function getComboFamiliaProducto() {

    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogofamilia/productos/lista", 'get');
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}



export async function getListaFamiliaProducto() {

    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogofamilia/productos/catalogo", 'get');
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function getListaFamiliaProductoUpdate(json) {
    let jsonfile = {
        "idfamilia_productos": json.idfamilia_productos,
        "familia": json.familia, "descripcion": json.descripcion, "idestatus": json.idestatus
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogofamilia/productos/guardar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function getComboTipoProducto() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogotipo/productos/lista", 'get');
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaTipoProducto() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogotipo/productos/catalogo", 'get');
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function getListaTipoProductoUpdate(json) {
    let jsonfile = {
        "idtipo_producto": json.idtipo_producto,
        "tipo": json.tipo,
        "descripcion": json.descripcion,
        "idfamilia_productos": json.idfamilia_productos,
        "idestatus": json.idestatus
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogotipo/productos/guardar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaTipoFamilia() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogotipo/clientes/catalogo", 'get');
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


///////////////////////////////////////////////////////////////////////////////////////////////////
//INICIO Sección APIS para Catálogo Presentación de Productos

export async function getListaPresentaciónDeProductos() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/empaques/catalogo", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaPresentacionDeProductosDisponibles() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/empaques/lista", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaPresentaciónDeProductosUpdate(json) {
    let jsonfile = {
        "idcatalogo_empaque": json.idcatalogo_empaque,
        "empaque": json.empaque,
        "descripcion": json.descripcion,
        "idestatus": json.idestatus
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/empaques/guardar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
///////////////////////////////////////////////////////////////////////////////////////////////////
//FIN Sección APIS para Catálogo Presentación de Productos

///////////////////////////////////////////////////////////////////////////////////////////////////
//INICIO Sección APIS para Catálogo Tipo Plagas

export async function getComboTipoPlagas() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogotipo/plagas/lista", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaTipoPlagas() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogotipo/plagas/catalogo", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function getListaTipoPlagasUpdate(json) {
    let jsonfile = {
        "idtipo_plaga": json.idtipo_plaga,
        "tipo": json.tipo,
        "descripcion": json.descripcion,
        "idestatus": json.idestatus
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogotipo/plagas/guardar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
///////////////////////////////////////////////////////////////////////////////////////////////////
//FIN Sección APIS para Catálogo Tipo Plagas

///////////////////////////////////////////////////////////////////////////////////////////////////
//INICIO Sección APIS para Catálogo Unidades de medida

export async function getListaUnidadesDeMedidaDisponibles() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/unidad-medida/lista", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaUnidadesDeMedida() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/unidad-medida/catalogo", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function getListaUnidadesDeMedidaUpdate(json) {
    let jsonfile = {
        "idcatalogo_unidadmedida": json.idcatalogo_unidadmedida,
        "unidadmedida": json.unidadmedida,
        "clave": json.clave,
        "descripcion": json.descripcion,
        "idestatus": json.idestatus
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/unidad-medida/guardar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
///////////////////////////////////////////////////////////////////////////////////////////////////
//FIN Sección APIS para Catálogo Unidades de medida



export async function getComboTipoCultivo() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogotipo/cultivos/lista", 'get');
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaTipoCultivo() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "cultivo/tipocultivo/catalogo", 'get');
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function getListaTipoCultivoUpdate(json) {
    let jsonfile = {
        "idtipo_cultivo": json.idtipo_cultivo,
        "tipo": json.tipo,
        "descripcion": json.descripcion,
        "idtipo_corte": json.idtipo_corte,
        "idestatus": json.idestatus
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "cultivo/tipocultivo/guardar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaCultivo() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/cultivos/catalogo", 'get');
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function getListaCultivoUpdate(json) {
    let jsonfile = {
        "idcatalogo_cultivo": json.idcatalogo_cultivo,
        "cultivo": json.cultivo,
        "descripcion": json.descripcion,
        "idtipo_cultivo": json.idtipo_cultivo,
        "idestatus": json.idestatus
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/cultivos/guardar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaIngredienteActivo() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/ingrediente-activo/catalogo", 'get');
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function getListaIngredienteActivoUpdate(json) {
    let jsonfile = {
        "idcatalogo_ingredientesactivos": json.idcatalogo_ingredientesactivos,
        "ingredienteactivo": json.ingredienteactivo, "descripcion": json.descripcion, "idestatus": json.idestatus
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/ingrediente-activo/guardar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaAlmacen() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogotipo/almacen/lista", 'get');
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaMovimientoAlmacen() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/movimiento-almacen/catalogo", 'get');
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function getListaMovimientoAlmacenUpdate(json) {
    let jsonfile = {
        "idcatalogo_movimientoalmacen": json.idcatalogo_movimientoalmacen,
        "movimientoalmacen": json.movimientoalmacen, "descripcion": json.descripcion, "idestatus": json.idestatus
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/movimiento-almacen/guardar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

///////////////////////////////////////////////////////////////////////////////////////////////////
//INICIO Sección APIS para Catálogo Áreas de trabajo

export async function getComboAreasDeTrabajo() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/areas/lista", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaAreasDeTrabajo() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/areas/catalogo", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function getListaAreasDeTrabajoUpdate(json) {
    let jsonfile = {
        "idcatalogo_areas": json.idcatalogo_areas,
        "area": json.area,
        "descripcion": json.descripcion,
        "idestatus": json.idestatus
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/areas/guardar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
///////////////////////////////////////////////////////////////////////////////////////////////////
//FIN Sección APIS para Catálogo Áreas de trabajo

///////////////////////////////////////////////////////////////////////////////////////////////////
//INICIO Sección APIS para Catálogo Plagas
export async function getComboPlagas() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/plagas/lista", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaPlagas() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/plagas/catalogo", 'get');
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function getListaPlagasUpdate(json) {
    let jsonfile = {
        "idcatalogo_plaga": json.idcatalogo_plaga,
        "plaga": json.plaga,
        "descripcion": json.descripcion,
        "idtipo_plaga": json.idtipo_plaga,
        "idestatus": json.idestatus
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/plagas/guardar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
///////////////////////////////////////////////////////////////////////////////////////////////////
//FIN Sección APIS para Catálogo Plagas

///////////////////////////////////////////////////////////////////////////////////////////////////
//INICIO Sección APIS para Catálogo Documentos

export async function getListaDocumentos() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/documentos/catalogo", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function getListaDocumentospdate(json) {
    let jsonfile = {
        "idcatalogo_documentos": json.idcatalogo_documentos,
        "documento": json.documento,
        "descripcion": json.descripcion,
        "idtipo_documento": json.idtipo_documento,
        "idestatus": json.idestatus
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/documentos/guardar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
///////////////////////////////////////////////////////////////////////////////////////////////////
//FIN Sección APIS para Catálogo Documentos


export async function getListaPuestosTrabajo() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/puestos/catalogo", 'get');
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function getListaPuestosTrabajoUpdate(json) {
    let jsonfile = {
        "idcatalogo_puestos": json.idcatalogo_puestos,
        "puesto": json.puesto,
        "descripcion": json.descripcion,
        "idcatalogo_areas": json.idcatalogo_areas,
        "idestatus": json.idestatus
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/puestos/guardar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}



///////////////////////////////////////////////////////////////////////////////////////////////////
//INICIO Sección APIS para Catálogo Producto

export async function getListaInformacionGeneralUpdate(json) {
    let jsonfile = {
        "idcatalogoproducto": json.idcatalogoproducto,
        "clave": json.clave,
        "nombrecomercial": json.nombrecomercial,
        "idtipoproducto": json.idtipoproducto,
        "idcatalogo_unidadmedida": json.idcatalogo_unidadmedida,
        "registrocofepris": json.registrocofepris,
        "bandatoxicologica": json.bandatoxicologica,
        "intervaloseguiridad": json.intervaloseguiridad,
        "tiemporeentrada": json.tiemporeentrada,
        "dosisminima": json.dosisminima,
        "dosismaxima": json.dosismaxima,
        "volumenagua": json.volumenagua,
        "idestatus": json.idestatus
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/productos/guardar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaIngredientesActivos() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/ingrediente-activo/lista", 'get');
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function getListaIngredientesActivosUpdate(json) {
    let jsonfile = {
        "idcatalogoproducto_ingredienteactivo": json.idcatalogoproducto_ingredienteactivo,
        "idcatalogoproducto": json.idcatalogoproducto,
        "idcatalogo_ingredientesactivos": json.idcatalogo_ingredientesactivos,
        "concentracion": json.concentracion,
        "descripcion": json.descripcion
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/productos/ingredienteactivo/guardar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function deleteProductoIngredienteActivo(json) {

    let jsonfile = {
        "idcatalogoproducto_ingredienteactivo": json.idcatalogoproducto_ingredienteactivo, "password": json.password
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/productos/ingredienteactivo/eliminar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaPlagasControlar() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/plagas/catalogo", 'get');
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function getListaPlagasControlarUpdate(json) {
    let jsonfile = {
        "idcatalogoproducto_plaga": json.idcatalogoproducto_plaga,
        "idcatalogoproducto": json.idcatalogoproducto,
        "idcatalogo_plaga": json.idcatalogo_plaga,
        "descripcion": json.descripcion
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/productos/plagaacontrolar/guardar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function deleteProductoPlagaControlar(json) {

    let jsonfile = {
        "idcatalogoproducto_plaga": json.idcatalogoproducto_plaga, "password": json.password
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/productos/plagaacontrolar/eliminar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaCultivosAutorizados() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/plagas/catalogo", 'get');
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function getListaCultivosAutorizadosUpdate(json) {
    let jsonfile = {
        "idcatalogoproducto_cultivo": json.idcatalogoproducto_cultivo,
        "idcatalogoproducto": json.idcatalogoproducto,
        "idcatalogo_cultivo": json.idcatalogo_cultivo,
        "descripcion": json.descripcion
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/productos/cultivosautorizados/guardar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function deleteProductoCultivoAutorizado(json) {

    let jsonfile = {
        "idcatalogoproducto_cultivo": json.idcatalogoproducto_cultivo, "password": json.password
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/productos/cultivosautorizados/eliminar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaPresentacionesUpdate(json) {
    let jsonfile = {
        "idcatalogoproducto_empaque": json.idcatalogoproducto_empaque,
        "idcatalogoproducto": json.idcatalogoproducto,
        "idcatalogo_empaque": json.idcatalogo_empaque,
        "cantidad": json.cantidad,
        "descripcion": json.descripcion
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/productos/presentaciones/guardar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function deleteProductoPresentaciones(json) {

    let jsonfile = {
        "idcatalogoproducto_empaque": json.idcatalogoproducto_empaque, "password": json.password
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/productos/presentaciones/eliminar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaProductoDocumentoUpdate(formdata) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/productos/documentos/guardar", 'post', formdata, "formdata");
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function deleteProductoDocumento(json) {

    let jsonfile = {
        "idproductodocumento": json.idproductodocumento, "password": json.password
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/productos/documentos/eliminar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
///////////////////////////////////////////////////////////////////////////////////////////////////
//FIN Sección APIS para Catálogo Producto


///////////////////////////////////////////////////////////////////////////////////////////////////
//INICIO Sección APIS para Catálogo Tipo Documentos

export async function getListaProveedores() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/proveedores/catalogo", 'get');
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function getListaProveedoresUpdate(json) {
    let jsonfile = {
        "idcatalogo_proveedores": json.idcatalogo_proveedores,
        "clave": json.clave,
        "nombre": json.nombre,
        "idtipo_proveedor": json.idtipo_proveedor,
        "comentario": json.comentario,
        "idestatus": json.idestatus
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/proveedores/guardar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
///////////////////////////////////////////////////////////////////////////////////////////////////
//FIN Sección APIS para Catálogo Tipo Documentos

export async function getListaTipoSuelo() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogotipo/suelo/catalogo", 'get');
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function getListaTipoSueloUpdate(json) {
    let jsonfile = {
        "idtiposuelo": json.idtiposuelo,
        "tiposuelo": json.tiposuelo,
        "descripcion": json.descripcion,
        "idestatus": json.idestatus
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogotipo/suelo/guardar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


///////////////////////////////////////////////////////////////////////////////////////////////////
//INICIO Sección APIS para Catálogo Estatus plantación
export async function getListaEstatusPlantacion() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/estatus-cultivo/catalogo", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function getListaEstatusPlantacionUpdate(json) {
    let jsonfile = {
        "idestatuscultivo": json.idestatuscultivo,
        "estatuscultivo": json.estatuscultivo,
        "descripcion": json.descripcion,
        "idestatus": json.idestatus
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/estatus-cultivo/guardar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
///////////////////////////////////////////////////////////////////////////////////////////////////
//FIN Sección APIS para Catálogo Estatus plantación

///////////////////////////////////////////////////////////////////////////////////////////////////
//INICIO Sección APIS para Catálogo Zona de cultivo

export async function getListaZonaCultivo() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/zonas/catalogo", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function getListaZonaCultivoUpdate(json) {
    let jsonfile = {
        "idzona": json.idzona,
        "zona": json.zona,
        "descripcion": json.descripcion,
        "idestatus": json.idestatus
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/zonas/guardar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
///////////////////////////////////////////////////////////////////////////////////////////////////
//FIN Sección APIS para Catálogo Zona de cultivo


///////////////////////////////////////////////////////////////////////////////////////////////////
//INICIO Sección APIS para obtener zonas 
export async function getListaZonas() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/zonas/lista", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


///////////////////////////////////////////////////////////////////////////////////////////////////
//FIN Sección APIS para zonas 

///////////////////////////////////////////////////////////////////////////////////////////////////
//INICIO Sección APIS para obtener lista estatus de siembra
export async function getListaEstatusSiembra() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/estatus-cultivo/lista", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


///////////////////////////////////////////////////////////////////////////////////////////////////
//FIN Sección APIS para lista estatus de siembra

///////////////////////////////////////////////////////////////////////////////////////////////////
//INICIO Sección APIS para Catálogo Patrón Injerto
export async function getListaCatalogoPatronInjerto() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/patron-injerto/catalogo", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function getListaPatronInjertoUpdate(json) {
    let jsonfile = {
        "idpatroninjerto": json.idpatroninjerto, "patroninjerto": json.patroninjerto,
        "descripcion": json.descripcion, "idestatus": json.idestatus
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/patron-injerto/guardar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
///////////////////////////////////////////////////////////////////////////////////////////////////
//FIN Sección APIS para Catálogo Patrón Injerto

///////////////////////////////////////////////////////////////////////////////////////////////////
//INICIO Sección APIS para Catálogo Ranchos Cultivo
export async function getCatalogoRanchosCultivo() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/ranchos/catalogo", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function getListaRanchosCultivoUpdate(formdata) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/ranchos/guardar", 'post', formdata);//, "formdata");
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
///////////////////////////////////////////////////////////////////////////////////////////////////
//FIN Sección APIS para Catálogo Ranchos Cultivo

export async function getListaTipoCultivosDisponibles() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogotipo/cultivos/lista", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaCultivosDisponibles() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/cultivos/lista", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaPatronInjertoDisponibles() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/patron-injerto/lista", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaTipoSueloDisponibles() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogotipo/suelo/lista", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaRanchosDisponibles() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/ranchos/lista", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaLoteCultivos() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/lote-cultivo/catalogo", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaLoteCultivosUpdate(json) {
    let jsonfile = {
        "idlote": json.idlote,
        "lote": json.lote,
        "idrancho": json.idrancho,
        //"idzona": json.idzona,
        "descripcion": json.descripcion,
        "fechainicio": json.fechainicio,
        "fechafin": json.fechafin,
        "idcultivo": json.idcultivo,
        //"idtipocultivo": this.state.tipocultivo,
        "idpatron": json.idpatron,

        "ph": json.ph,
        "densidad": json.densidad,
        "marcoplantacion": json.marcoplantacion,
        "idestatus": json.idestatus,
    };

    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/lote-cultivo/guardar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
///////////////////////////////////////////////////////////////////////////////////////////////////
//FIN Sección APIS para Catálogo Ranchos Cultivo

///////////////////////////////////////////////////////////////////////////////////////////////////
//INICIO Sección APIS para obtener áreas 
export async function getListaLotes(idrancho) {
    var resp = [];
    /*let jsonfile = {
         "idrancho": idrancho
     };*/
    try {
        var datos = await consumirApi(apigeneral + "catalogos/lote-cultivo/lista", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
///////////////////////////////////////////////////////////////////////////////////////////////////
//FIN Sección APIS para áreas 


///////////////////////////////////////////////////////////////////////////////////////////////////
//INICIO Sección APIS para obtener catálogo tabla de cultivos
export async function getListaCatalogoTablaCultivos() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/tablas/catalogo", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaCatalogoTablaCultivosUpdate(json) {
    let jsonfile = {
        "idtabla": json.idtabla,
        "tabla": json.tabla,
        "idlote": json.idlote,
        "descripcion": json.descripcion,
        "lotetotal": json.lotetotal,
        "loteefectivo": json.loteefectivo,
        "idtiposuelo": json.idtiposuelo,
        "plantastotales": json.plantastotales,
        "plantel": json.plantel,
        "desarrollo": json.desarrollo,
        "productivas": json.productivas,
        "fallas": json.fallas,
        "idestatuscultivo": json.idestatuscultivo,
        "idestatus": json.idestatus
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/tablas/guardar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
///////////////////////////////////////////////////////////////////////////////////////////////////
//FIN Sección APIS para catálogo tabla de cultivos

export async function getComboUnidadMedida() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/unidad-medida/lista", 'get');
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaProductosUpdate(json) {
    let jsonfile = {
        "idcatalogoproducto": json.idcatalogoproducto,
        "clave": json.clave,
        "nombrecomercial": json.nombrecomercial,
        "idtipoproducto": json.idtipoproducto,
        "idcatalogo_unidadmedida": json.idcatalogo_unidadmedida,
        "registrocofepris": json.registrocofepris,
        "bandatoxicologica": json.bandatoxicologica,
        "intervaloseguiridad": json.intervaloseguiridad,
        "tiemporeentrada": json.tiemporeentrada,
        "dosisminima": json.dosisminima,
        "dosismaxima": json.dosismaxima,
        "volumenagua": json.volumenagua,
        "idestatus": json.idestatus
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/productos/guardar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaAlertasAlmacen() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/almacenes/alertas/catalogo", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaAlertasAlmacenUpdate(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/almacenes/alertas/guardar", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


///////////////////////////////////////////////////////////////////////////////////////////////////
//INICIO Sección APIS para Catálogo Productos

export async function getListaProductoInformacion(idproducto) {
    var jsonfile = {
        "idcatalogoproducto": idproducto
    }
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/productos/informacion", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

/*export async function getListaProductosDisponibles() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/empaques/lista", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}*/

/*export async function getListaProductosUpdate(json) {
    let jsonfile = {
        "idcatalogo_empaque": json.idcatalogo_empaque,
        "empaque": json.empaque,
        "descripcion": json.descripcion,
        "idestatus": json.idestatus
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/empaques/guardar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}*/
///////////////////////////////////////////////////////////////////////////////////////////////////
//FIN Sección APIS para Catálogo Productos



///////////////////////////////////////////////////////////////////////////////////////////////////
//INICIO Sección APIS para Catálogo Almacenes

export async function getListaTipoAlmacenes() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogotipo/almacen/lista", 'get');
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getCatalogoAlmacenes() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/almacenes/catalogo", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaAlmacenes() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/almacenes/lista", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function getListaAlmacenesUpdate(json) {
    let jsonfile = {
        "idcatalogoalmacen": json.idcatalogoalmacen,
        "nomnbrealmacen": json.nomnbrealmacen,
        "descripcion": json.descripcion,
        "idtipoalmacen": json.idtipoalmacen,
        "idestatus": json.idestatus,
        "idrancho": json.idrancho
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/almacenes/guardar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
///////////////////////////////////////////////////////////////////////////////////////////////////
//FIN Sección APIS para Catálogo Almacenes

///////////////////////////////////////////////////////////////////////////////////////////////////
//INICIO Sección API CATÁLOGO DE PRODUCTOS
export async function getListaProductos() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/productos/catalogo", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
export async function getLista2Productos() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/productos/lista", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
///////////////////////////////////////////////////////////////////////////////////////////////////
//FIN Sección APIS CATÁLOGO DE PRODUCTOS

///////////////////////////////////////////////////////////////////////////////////////////////////
//INICIO Sección APIS para Catálogo inventario Almacenes


export async function getAlmacenesInformación(idcatalogoalmacen) {
    let jsonfile = {
        "idcatalogoalmacen": idcatalogoalmacen,
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/almacenes/informacion", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaTipoMovimientoInventario() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/generales/tipomovimientoinventario", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaMovimientoInventario() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/generales/movimientoinventario", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getAlmacenesInformaciónGuardar(json, idmovimientoinventario) {
    let jsonfile1 = {
        "idcatalogoalmacen": json.idcatalogoalmacen,
        "id_movimientos_inventario": json.id_movimientos_inventario,
        "productos": json.productos,

    };
    let jsonfile2 = {
        "idcatalogoalmacen": json.idcatalogoalmacen,
        "id_movimientos_inventario": json.id_movimientos_inventario,
        "productos": json.productos,
        "idcatalogoalmacendestino": json.idcatalogoalmacendestino,
    };
    var jsonfile = idmovimientoinventario != 3 ? jsonfile1 : jsonfile2;
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/almacenes/productos/guardar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

///////////////////////////////////////////////////////////////////////////////////////////////////
//FIN Sección APIS para Catálogo  inventario Almacenes

//////////////////////////////////////////////////////////////////////////////////////////////////
// INICIO apis catalogo inventario almacenes generales 

export async function getAlmacenesGeneralesInformación(idcatalogoalmacen) {
    let jsonfile = {
        "idcatalogoalmacengeneral": idcatalogoalmacen,
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/almacenproductogeneral/informacion", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function getAlmacenesGeneralesInformaciónGuardar(json, idmovimientoinventario) {
    let jsonfile1 = {
        "idcatalogoalmacen": json.idcatalogoalmacen,
        "id_movimientos_inventario": json.id_movimientos_inventario,
        "productos": json.productos,

    };
    let jsonfile2 = {
        "idcatalogoalmacen": json.idcatalogoalmacen,
        "id_movimientos_inventario": json.id_movimientos_inventario,
        "productos": json.productos,
        "idcatalogoalmacendestino": json.idcatalogoalmacendestino,
    };
    var jsonfile = idmovimientoinventario != 3 ? jsonfile1 : jsonfile2;
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/almacenes/productos/guardargenerales", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}






export async function getAlmacenesEquiposInformación(idcatalogoalmacen) {
    let jsonfile = {
        "idcatalogoalmacenequipo": idcatalogoalmacen,
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/almacenequipo/informacion", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getAlmacenesEquiposInformaciónGuardar(json, idmovimientoinventario) {
    let jsonfile1 = {
        "idcatalogoalmacen": json.idcatalogoalmacen,
        "id_movimientos_inventario": json.id_movimientos_inventario,
        "productos": json.productos,

    };
    let jsonfile2 = {
        "idcatalogoalmacen": json.idcatalogoalmacen,
        "id_movimientos_inventario": json.id_movimientos_inventario,
        "productos": json.productos,
        "idcatalogoalmacendestino": json.idcatalogoalmacendestino,
    };
    var jsonfile = idmovimientoinventario != 3 ? jsonfile1 : jsonfile2;
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/almacenes/productos/guardarequipos", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
//FIN Sección APIS para Catálogo  inventario Almacenes generales
//////////////////////////////////////////////////////////////////////////////////////////////////

export async function getListaAlertasInformacion(idalmacen) {
    let jsonfile = {
        "idcatalogoalmacen": idalmacen,
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/almacenes/alertainformacion", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function eliminarAlerta(jsonfile) {
   
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/almacenes/alertas/eliminar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaPuestosDeTrabajo() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/puestos/lista", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaTipoUsuarios() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogotipo/usuarios/lista", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
///////////////////////////////////////////////////////////////////////////////////////////////////
export async function getListaAsignarSalario(jsonfile) {
    var resp = [];
    try {

        var datos = await consumirApi(apigeneral + "usuarios/empleados-salario-asignar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
///////////////////////////////////////////////////////////////////////////////////////////////////
export async function getListaSalarios() {
    var resp = [];
    try {


        var datos = await consumirApi(apigeneral + "usuarios/empleados-salario", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

///////////////////////////////////////////////////////////////////////////////////////////////////
export async function getListaAgregarusuario() {
    var resp = [];
    try {


        var datos = await consumirApi(apigeneral + "usuarios/empleados-rancho", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
///////////////////////////////////////////////////////////////////////////////////////////////////
//INICIO Sección APIS para obtener catálogo usuarios
export async function getListaCatalogoUsuarios() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/usuarios/catalogo", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaCatalogoUsuariosLista() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/usuarios/lista", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaCatalogoUsuariosUpdate(json) {
    let jsonfile =
    {
        "idcatalogousuarios": json.idcatalogousuarios,
        "nombres": json.nombres,
        "apellidos": json.apellidos,
        "email": json.email,
        "telefono": json.telefono,
        "calle": json.calle,
        "numeroexterior": json.numeroexterior,
        "numerointerior": json.numerointerior,
        "codigopostal": json.codigopostal,
        "colonia": json.colonia,
        "localidad": json.localidad,
        "municipio": json.municipio,
        "estado": json.estado,
        "pais": json.pais,
        "referencia": json.referencia,
        "idpuesto": json.idpuesto,
        "idtipousuario": json.idtipousuario,
        "idestatus": json.idestatus,
        "acceso": json.acceso,
        "usuario": json.usuario
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/usuarios/guardar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function setEstatusUsuario(json) {
    let jsonfile = {
        "idcatalogousuarios": json.idcatalogousuarios,
        "idestatus": json.idestatus

    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/usuarios/activar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function setRestablecerUsuario(json) {
    let jsonfile = {
        "idcatalogousuarios": json.idcatalogousuarios

    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/usuarios/restablecer", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function setGenerarNombreUsuario(json) {
    let jsonfile = {
        "nombres": json.nombres,
        "apellidos": json.apellidos

    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/usuarios/generarusername", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}



export async function Asignarusuario(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "usuarios/empleados-rancho-agregar", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function EliminarAsignarusuario(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "usuarios/empleados-rancho-eliminar", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function EditarAsignarusuario(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "usuarios/empleados-rancho-editar", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function setVerificarNombreUsuario(json) {
    let jsonfile = {
        "username": json.username
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/usuarios/verificarusername", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getDetallesUsuario(jsonfile) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/usuarios/detalles", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

///////////////////////////////////////////////////////////////////////////////////////////////////
//FIN Sección APIS para catálogo usuarios


export async function getTipoModulos() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/generales/tipo_modulos", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getTiposPermisos() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/generales/tipospermisos", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getModulos() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/generales/modulos", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getPermisosUsuarios(idtipousuario) {
    let jsonfile = {
        "idtipousuario": idtipousuario,
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "tipousuarios/accesos/lista", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function guardarAccesosTipoUsuario(json) {
    let jsonfile = {
        "idtipousuario": 1,
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "tipousuarios/accesos/guardar", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function guardarDocumentoPerfilUsuario(formdata) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/usuarios/documentos/guardar", 'post', formdata, "formdata");
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function eliminarDocumentoPerfilUsuario(json) {
    let jsonfile = {
        "idcatalogousuarios_documentos": json.idcatalogousuarios_documentos,
        "password": json.password
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/usuarios/documentos/eliminar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function cambiarPasswordPerfilUsuario(json) {
    let jsonfile = {
        "passwordactual": json.passwordactual,
        "nuevopassword": json.nuevopassword
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/usuarios/guardarpassword", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getPerfilUsuario() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/usuarios/perfil", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaTablaCultivos() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/tablas/lista", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

///////////////////////////////////////////////////////////////////////////////////////////////////
// INICIO Sección APIS para catálogo requisiciones
export async function getListaEstatusRequisicion() {

    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/estatusrequisiciones/lista", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaProveedoresRequisicion() {

    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogotipo/requisiciones/lista", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getCatalogoRequisiciones() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/requisiciones/catalogo", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function updateDoumentoRequisicion(formdata) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/requisiciones/documentos/guardar", 'post', formdata);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function deleteRequisicionDocumento(json) {

    let jsonfile = {
        "idcatalogo_requisicion_documentos": json.idcatalogo_requisicion_documentos, "password": json.password
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/requisiciones/documentos/eliminar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getRequisicionesUpdate(json) {
    let jsonfile = {
        "idcatalogo_requisicion": json.idcatalogo_requisicion,
        "password": json.password,
        "idestatusrequisicion": json.idestatusrequisicion,
        "comentario": json.comentario,
        "condicion": json.condicion,
        "nuevoprecio": json.nuevoprecio
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/requisiciones/estatus", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
///////////////////////////////////////////////////////////////////////////////////////////////////
// FIN Sección APIS para catálogo requisiciones

//// Funciones para la ventana de información de querisición
export async function getRequisicionInformacion(json) {
    let jsonfile = {
        "idrequisicion": json.idrequisicion
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/requisiciones/informacion", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}





///////////////////////////////////////////////////////////////////////////////////////////////////
//INICIO Sección APIS para Catálogo Tipo Requisición

export async function getListaTipoRequisicion() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogotipo/requisiciones/lista", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getCatalogoTipoRequisicion() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogotipo/requisiciones/catalogo", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function getListaTipoRequisicionUpdate(json) {
    let jsonfile = {
        "idtipo_requisicion": json.idtipo_requisicion,
        "tipo": json.tipo,
        "descripcion": json.descripcion,
        "idestatus": json.idestatus
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogotipo/requisiciones/guardar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
///////////////////////////////////////////////////////////////////////////////////////////////////
//FIN Sección APIS para Catálogo Tipo Requisición




///////////////////////////////////////////////////////////////////////////////////////////////////
//INICIO Sección APIS para Catálogo nuevo Requisición

export async function getListaEstatusRequesicion() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/estatusrequisiciones/lista", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getInformacionRequesicion(idrequisicion) {
    var resp = [];
    var jsonfile = {
        "idrequisicion": idrequisicion
    }
    try {
        var datos = await consumirApi(apigeneral + "catalogos/requisiciones/informacion", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }

    return resp;
}


export async function guardarNuevaRequesicion(json) {

    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/requisiciones/guardar", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function agregarProductoEmergente(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/productos/agregar-producto-emergente", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function obtenerUltimoProveedorSugerido(idproducto) {
    var json = {
        idproducto: idproducto
    }
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/requisiciones/proveedorsugerido", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
///////////////////////////////////////////////////////////////////////////////////////////////////
//FIN Sección APIS para Catálogo nuevo Requisición

///////////////////////////////////////////////////////////////////////////////////////////////////
//INICIO Sección APIS para Catálogo Tipo Actividades

export async function getListaTipoActividades() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogotipo/actividades/lista", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getCatalogoTipoActividades() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogotipo/actividades/catalogo", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function setCatalogoTipoActividadesUpdate(json) {
    let jsonfile = {
        "idtipo_actividad": json.idtipo_actividad,
        "tipo": json.tipo,
        "descripcion": json.descripcion,
        "idestatus": json.idestatus
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogotipo/actividades/guardar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
///////////////////////////////////////////////////////////////////////////////////////////////////
//FIN Sección APIS para Catálogo Tipo Actividades

///////////////////////////////////////////////////////////////////////////////////////////////////
//INICIO Sección APIS para Catálogo Actividades

export async function getListaActividades() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/actividad/lista", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getCatalogoActividades() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/actividad/catalogo", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function setCatalogoActividadesUpdate(json) {
    let jsonfile = {
        "idcatalogo_actividad": json.idcatalogo_actividad,
        "nombre": json.nombre,
        "idtipo_actividad": json.idtipo_actividad,
        "comentario": json.comentario,
        "idestatus": json.idestatus
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/actividad/guardar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
///////////////////////////////////////////////////////////////////////////////////////////////////
//FIN Sección APIS para Catálogo Actividades



///////////////////////////////////////////////////////////////////////////////////////////////////
//INICIO Sección APIS para Catálogo Tipo Equipos
export async function getCatalogoTipoEquipos() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogotipo/equipos/catalogo", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function updateTipoEquipo(json) {

    let jsonfile = {
        "idtipo_equipo": json.idtipo_equipo,
        "tipo": json.tipo,
        "descripcion": json.descripcion,
        "idestatus": json.idestatus
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogotipo/equipos/guardar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaTipoEquipos() {

    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogotipo/equipos/lista", 'get');
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
///////////////////////////////////////////////////////////////////////////////////////////////////
//FIN Sección APIS para Catálogo Tipo Equipos

///////////////////////////////////////////////////////////////////////////////////////////////////
//INICIO Sección APIS para Catálogo Equipos
export async function getCatalogoEquipos() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/equipos/catalogo", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function updateEquipo(jsonfile) {

   /* let jsonfile = {
        "idequipo": json.idequipo,
        "idtipoequipo": json.idtipoequipo,
        "nombreclave": json.nombreclave,
        "marca": json.marca,
        "modelo": json.modelo,
        "descripcion": json.descripcion,
        "idestatus": json.idestatus,
    };*/
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/equipos/guardar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaEquipos() {

    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/equipos/lista", 'get');
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
///////////////////////////////////////////////////////////////////////////////////////////////////
//FIN Sección APIS para Catálogo Equipos


//llamadas a servicios para  maquinarias
export async function getListaTipoMaquinarias() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogotipo/maquinaria/lista", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getCatalogoTipoMaquinarias() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogotipo/maquinaria/catalogo", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function guardarTipoMaquinaria(json) {

    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogotipo/maquinaria/guardar", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaMaquinarias() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/maquinaria/lista", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getCatalogoMaquinarias() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/maquinaria/catalogo", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function guardarMaquinaria(json) {

    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/maquinaria/guardar", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


//llamadas a servicios para  combustibles
export async function getListaTipoCombustibles() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogotipo/combustible/lista", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getCatalogoTipoCombustibles() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogotipo/combustible/catalogo", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function guardarTipoCombustible(json) {

    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogotipo/combustible/guardar", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

////////// INICIO: Funciones del Catálogo de Almacenes de Combustible //////////
export async function getCatalogoAlmacenesCombustible() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/almacencombustible/catalogo", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaAlmacenesCombustible() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/almacencombustible/lista", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function updateCatalogoAlmacenCombustible(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/almacencombustible/guardar", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
////////// FIN: Funciones del Catálogo de Almacenes de Combustible //////////

////////// INICIO: Funciones de la vista Almacén de Combustible //////////
export async function getListaCombustibles() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogotipo/combustibles/lista", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function updateAlmacenCombustible(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/almacencombustible/guardarcombustible", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

//getCatalogoTipoEquipos
export async function getInformacionAlmacenCombustible(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/almacencombustible/informacion", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getCatalogoTipoMovimientoCombustible() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/almacencombustible/tipomovimientocombustible", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getCatalogoMovimientoCombustible() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/almacencombustible/movimientocombustible", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function updateMovimientoAlmacenCombustible(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/almacencombustible/movimientos", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
////////// FIN: Funciones de la vista Almacén de Combustible //////////



////////// INICIO: Funciones del Catálogo de Almacenes de productos generales //////////
export async function getCatalogoAlmacenesProductosGenerales() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/almacenproductogeneral/catalogo", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaAlmacenesProductosGenerales() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/almacenproductogeneral/lista", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function updateCatalogoAlmacenProductosGenerales(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/almacenproductogeneral/guardar", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
////////// FIN: Funciones del Catálogo de Almacenes de Combustible //////////



////////// INICIO: Funciones del Catálogo de Almacenes de equipos y herramientas //////////
export async function getCatalogoAlmacenesEquiposyHerramientas() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/almacenequipo/catalogo", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaAlmacenesEquiposyHerramientas() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/almacenequipo/lista", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function updateCatalogoAlmacenEquiposyHerramientas(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/almacenequipo/guardar", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
////////// FIN: Funciones del Catálogo de Almacenes de Combustible //////////














//llamadas a servicios para  niveles de autorizacion
export async function getCatalogoNivelesAutorizacion() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/nivelesautorizacion/catalogo", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function guardarNivelesAutorizacion(json) {

    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/nivelesautorizacion/guardar", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


// Funciones para la Autorizar Cotizaciones
export async function getAutorizacionCotizacionesInformacionx(json) {
    let jsonfile = {
        "idcatalogo_requisicion": json.idcatalogo_requisicion
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/cotizaciones/informacion-cotizacion", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getCambiarEstatusAutorizacionCotizaciones(json) {

    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/cotizaciones/cambiarestatus", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

// cotizaciones

export async function getInformacionCotizacion(idrequisicion) {

    let jsonfile = {
        "idcatalogo_requisicion": idrequisicion
    };

    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/cotizaciones/informacion-cotizacion", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function guardarCotizacion(jsonfile) {

    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/cotizaciones/guardar-cotizacion", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function solicitarAutorizacionCotizacion(jsonfile) {

    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/cotizaciones/cambiarestatus", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function comprarCotizacion(idcotizacion) {

    let jsonfile = {
        "idcotizacion_proveedor": idcotizacion
    };

    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/cotizaciones/comprar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


////////// INICIO: Funciones del Catálogo Compras //////////

export async function getCatalogoCompras(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/compras/catalogo", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function getInformacionCompra(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/compras/informacion", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function eliminarCompra(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/compras/eliminarcompra", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function moverProductosCompra(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/almacenes/productos/movercompra", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function moverProductosGeneralesCompra(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/almacenes/productos/movercompragenerales", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function moverProductosGeneralesEquipos(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/almacenes/productos/movercompraequipos", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaEncargadosRancho() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/ranchos/lista-encargados", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function Getinfocsv(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "nominas/nomina-dump", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function moverProductosGeneralesAreasRanchosVehiculos(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/almacenes/productos/movercompraactivos", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function moverProductosGeneralesSoloEquipos(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/almacenes/productos/movercompraequipos_almacen", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}



export async function guardarCompraDirecta(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/compras/guardarcompradirecta", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function getInformacionCompraDirecta(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/compras/informacioncompradirecta", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function getCambiarEstatusCompraDirecta(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/compras/cambiarestatus", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}





////////// FIN: Funciones del Catálogo Compras //////////

//////////////////// Inicio: Funciones de prueba [ELIMINAR] ////////////////////
export async function consumirApiTestPalabras(apis) {

    const URL_BASE = 'https://api.api-ninjas.com/v1/'
    try {
        var consulta = await axios.get(URL_BASE + apis, {
            headers: { 'X-Api-Key': 'ce9/dWVSB/UKRhxYW5Ghsw==Y6Yq7RbL7BNyFY7I' },
        })
        return consulta.data
    } catch (error) {
        return -1;
    }

}

export async function getListaPalabras() {
    const pathapi = 'loremipsum?paragraphs=1'
    var resp = [];
    try {
        var datos = await consumirApiTestPalabras(pathapi)
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
//////////////////// Fin: Funciones de prueba [ELIMINAR] ////////////////////

////////// INICIO: Funciones del Catálogo Plan Anual //////////
export async function getCatalogoPlanAnual() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/presupuestos/catalogo", 'get')
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
////////// FIN: Funciones del Catálogo Plan Anual //////////


////////// INICIO: Funciones del Catálogo Propuesta Anual //////////

export async function getPresupuestosInformacion(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/presupuestos/informacion", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
export async function setPresupuestosInformacion(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/presupuestos/guardar-presupuesto", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getMeses() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/generales/meses", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function buscarPeriodo(periodo) {
    var json = {
        "periodo": periodo
    }
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/presupuestos/buscar-periodo", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

////////// FIN: Funciones del Catálogo Propuesta Anual //////////


export async function getProveedoresActivos() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/proveedores/lista", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

/////// INICIO: Modal encargados para el rancho (/catalogoranchoscultivo)
export async function getListaPuestosEncargados() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/ranchos/puestos-encargado-rancho", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaEncargados() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/ranchos/lista-encargados", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function updateEncargadosRanchos(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/ranchos/guardar-encargados", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
/////// INICIO: Modal encargados para el rancho (/catalogoranchoscultivo)

export async function getInformacionRequesicionRectificar(idrequisicion) {
    var resp = [];
    var jsonfile = {
        "idcotizacion": idrequisicion
    }
    try {
        var datos = await consumirApi(apigeneral + "catalogos/requisiciones/informacion-rectificarproductos", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }

    return resp;
}

export async function autorizarProductos(jsonfile) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/cotizaciones/cambio-autorizarproductos", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }

    return resp;
}

export async function guardarRectificacion(jsonfile) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/requisiciones/guardar-productosrectificados", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }

    return resp;
}

//para modal de detalles de la compra
export async function getDetallesCompra(jsonfile) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/compras/detalle", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }

    return resp;
}

//tipos de pago para el select en modal guardar pago
export async function getFormasPagoCompras() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogotipo/formas-de-pago/lista", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

//guarda un pago normal individual en la tabla pagos de la modal detalles
export async function guardarPagoCompras(formdata) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/compras/guardar-pago", 'post', formdata, "formdata");
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

//guarda una factura normal individual en la tabla facturas de la modal detalles
export async function guardarFacturaCompras(formdata) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/compras/guardar-factura", 'post', formdata, "formdata");
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

//elimina una factura normal individual
export async function eliminarFactura(jsonfile) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/compras/eliminar-factura", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }

    return resp;
}

//elimina un pago normal individual
export async function eliminarPago(jsonfile) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/compras/eliminar-pago", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }

    return resp;
}

//obtiene la lista de compras para la modal buscar compras en pago y factura global
export async function buscarComprasMismoProveedor(jsonfile) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/compras/buscar-compras-con-mismo-proveedor", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }

    return resp;
}

//guarda una factura global boton factura global
export async function guardarFacturaGlobalCompras(formdata) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/compras/guardar-factura-global", 'post', formdata, "formdata");
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

//cambia el descuento en la modal detalles
export async function agregarDescuento(jsonfile) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/compras/agregar-descuento", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }

    return resp;
}

//elimina toda la seccion de factura global en modal detalles
export async function eliminarFacturaGlobalGeneral(jsonfile) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/compras/eliminar-factura-global", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }

    return resp;
}

//elimina toda la seccion de pago global en modal detalles
export async function eliminarPagoGlobalGeneral(jsonfile) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/compras/eliminar-pago-global-general", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }

    return resp;
}

//guarda pago boton agregar pago en seccion de factura global
export async function guardarPagoFacturaGlobalCompras(formdata) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/compras/guardar-pago-factura-global", 'post', formdata, "formdata");
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

//elimina pago de la tabla pagos en seccion de factura global
export async function eliminarPagoFacturaGlobalCompras(jsonfile) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/compras/eliminar-pago-factura-global", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }

    return resp;
}

//editar factura global general
export async function getDetalleFacturaGlobalGeneral(jsonfile) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/compras/obtener-detalle-factura-global", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }

    return resp;
}

//obtiene las facturas de las compras seleccionadas en modal de guardar pago global
export async function buscarFacturasCompraSeleccionadaPagoGlobal(jsonfile) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/compras/buscar-facturas-compra", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

//editar pago global general
export async function getDetallePagoGlobalGeneral(jsonfile) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/compras/obtener-detalle-pago-global", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }

    return resp;
}

//guardar pago global general
export async function guardarPagoGlobalCompras(jsonfile) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/compras/agregar-facturas-pago-global", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }

    return resp;
}


//guarda pago boton agregar pago en seccion pago global
export async function guardarPagoPagoGlobalCompras(formdata) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/compras/guardar-pago-global", 'post', formdata, "formdata");
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

//elimina pago en la tabla de pagos en la seccion de pago global
export async function eliminarPagoPagoGlobalCompras(jsonfile) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/compras/eliminar-pago-global", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }

    return resp;
}

///////////////////////////////////////////////////////////////////////////////////////////////////
//INICIO Sección API CATÁLOGO DE PRODUCTOS Generales
export async function getCatalogoProductosGenerales() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/producto-general/catalogo", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
export async function getListaProductosGenerales() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/producto-general/lista", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function agregarProductoGeneral(jsonfile) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/producto-general/guardar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }

    return resp;
}
///////////////////////////////////////////////////////////////////////////////////////////////////
//FIN Sección APIS CATÁLOGO DE PRODUCTOS Generales




///////////////////////////////////////////////////////////////////////////////////////////////////
//INICIO Sección APIS para Catálogo Tipo Requisición General

export async function getListaTipoRequisicionGeneral() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogotipo/requisiciones-generales/lista", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getCatalogoTipoRequisicionGeneral() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogotipo/requisiciones-generales/catalogo", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function getListaTipoRequisicionGeneralUpdate(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogotipo/requisiciones-generales/guardar", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
///////////////////////////////////////////////////////////////////////////////////////////////////
//FIN Sección APIS para Catálogo Tipo Requisición General





//////////////////////////////////////////////////////////////////////////////////////////////////
//inicio tipo producto general 

export async function updateTipoProductoGeneral(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogotipo/producto-general/guardar", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getCatalogoTipoProductosGeneral() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogotipo/producto-general/catalogo", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaTipoProductosGeneral() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogotipo/producto-general/lista", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

// -------
export async function getCatalogoRequisicionesGenerales() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/requisiciones-generales/catalogo", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaDestinos() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogotipo/destino/lista", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaAlmacenProductoGeneral() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/almacenproductogeneral/lista", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaAlmacenEquipos() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/almacenequipo/lista", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


//////////////// cotización de productos generales ///////////////////

export async function getInformacionCotizacionProductoGeneral(idrequisicion) {
    var resp = [];
    let jsonfile = {
        "idcatalogo_requisicion": idrequisicion
    };
    try {
        var datos = await consumirApi(apigeneral + "catalogos/cotizaciones-generales/informacion-cotizacion", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function guardarCotizacionProductoGeneral(jsonfile) {
    var resp = [];

    try {
        var datos = await consumirApi(apigeneral + "catalogos/cotizaciones-generales/guardar-cotizacion", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function cambiarStatusCotizacionProductoGeneral(jsonfile) {
    var resp = [];

    try {
        var datos = await consumirApi(apigeneral + "catalogos/cotizaciones-generales/cambiarestatus", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function comprarCotizacionProductoGeneral(idcotizacion) {
    var resp = [];
    let jsonfile = {
        "idcotizacion_proveedor": idcotizacion
    };
    try {
        var datos = await consumirApi(apigeneral + "catalogos/cotizaciones-generales/comprar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

//requisición general //////////////////////////////////////
export async function guardarNuevaRequesicionGeneral(json) {

    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/requisiciones-generales/guardar", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getInformacionRequesicionGeneral(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/requisiciones-generales/informacion", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getRequisicionesGeneralesUpdate(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/requisiciones-generales/estatus", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function agregarProductoGeneralEmergente(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/producto-general/guardar-emergente", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

////////////////////////////////////////////////////
// INICIO CATÁLOGO PRODUCTOS ACTIVOS

export async function getCatalogoProductosActivos() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/almacenproductosactivos/catalogo", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getCatalogoProductosActivosInformacion(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/almacenproductosactivos/informacion", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function guardarMoverProductosActivos(json, idmovimientoinventario) {
    let jsonfile1 = {
        "idcatalogoalmacen": json.idcatalogoalmacen,
        "id_movimientos_inventario": json.id_movimientos_inventario,
        "productos": json.productos,

    };
    let jsonfile2 = {
        "idcatalogoalmacen": json.idcatalogoalmacen,
        "id_movimientos_inventario": json.id_movimientos_inventario,
        "productos": json.productos,
        "idcatalogoalmacendestino": json.idcatalogoalmacendestino,
    };
    var jsonfile = idmovimientoinventario != 3 ? jsonfile1 : jsonfile2;
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/almacenproductosactivos/guardar", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}







////////// INICIO: Funciones del Catálogo de vales //////////
export async function getCatalogoValesCombustible(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/compracombustible/vales/catalogo", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function guardarValeCombustible(formdata) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/compracombustible/vales/guardarvale", 'post', formdata);//, "formdata");
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function cancelarValeCombustible(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/compracombustible/vales/cancelar-vale", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function guardarCompraCombustible(formdata) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/compracombustible/vales/guardarcompracombustible", 'post', formdata);//, "formdata");
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}



export async function verCompraCombustible(formdata) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/compras/informacion-compracombustible", 'post', formdata);//, "formdata");
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


////////// FIN: Funciones del Catálogo de vales //////////





/////////////////////////  Notificaciones  ///////////////////
export async function getNotificaciones() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "notificaciones/autorizaciones", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

//////////////////////  Guardar IEPS //////////////////////////////
export async function getCatalogoIEPS() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/ieps/catalogo", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function guardarIEPS(json) {

    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/ieps/guardar", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getCatalogoIEPSLista() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/ieps/lista", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


//////////////////////////////////////////////////////////////////////////////////////////////////
// INICIO apis ordentrabajo

export async function guardarOrdenTrabajo(json) {

    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/ordentrabajo/guardar", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function reactivarOrdenTrabajo(json) {

    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/ordentrabajo/reactivar", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
} 

export async function getCatalogoOrdenTrabajo() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/ordentrabajo/catalogo", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function informacionOrdenTrabajo(json) {

    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/ordentrabajo/informacion", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function informacionReporteOrdenTrabajo(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/ordentrabajo/reporte-completo", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function cambiarEstatusOrdenTrabajo(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/ordentrabajo/cambiar-estatus", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getEstatusOrdenTrabajo() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/estatusordentrabajo/lista", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getCatalogoCuadrillas() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "cuadrilla/catalogo", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function guardarNuevaCuadrilla(json) {

    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "cuadrilla/nuevo", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function guardarEditarCuadrilla(json) {

    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "cuadrilla/guardar", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getCatalogoOrdenCosecha() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "cosecha/catalogo", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function guardarNuevaOrdenCosecha(json) {

    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "cosecha/nuevo", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function guardarEditarOrdenCosecha(json) {

    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "cosecha/guardar", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getDetallesOrdenCosecha(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "cosecha/detalles", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getDetallesOrdenCosechaGuardarTabla(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "cosecha/detalles-guardar-tablas", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getDetallesOrdenCosechaGuardarCuadrilla(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "cosecha/detalles-guardar-cuadrillas", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getDetallesOrdenCosechaEliminarTabla(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "cosecha/detalles-tabla-eliminar", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getDetallesOrdenCosechaEliminarCuadrilla(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "cosecha/detalles-cuadrilla-eliminar", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaTiposPago() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/tipopago/lista", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaCuadrillas() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "cuadrilla/lista", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function getListaCortes(){
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "cultivo/tipocorte/lista", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function guardarEditarBitacoraCosecha(json) {

    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "cosecha/bitacora/guardar", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function catalogoBitacoraCosecha(json) {

    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "cosecha/bitacora/catalogo", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function detallesBitacoraCosecha(json) {

    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "cosecha/bitacora/detalles", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaBitacoraTraslados(json) {

    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "bitacoratraslados/lista", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function getListaLotesCortes() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/lotes/lista", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaClientes() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/clientes/lista", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}



export async function gettempBitacoraTraslados(json) {

    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "bitacoratraslados/catalogo", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}



export async function postDetalleBitacora(json) {

    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "cosecha/bitacora/detallesguardar", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}



export async function eliminarDetalleBitacora(json) {

    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "cosecha/bitacora/detalleseliminar", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}



/** Bitacora cuadrillas */
export async function postListaBitacoraCuadrillas(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "bitacoracuadrillas/catalogo", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function postBitacoraCuadrillasUpdate(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "bitacoracuadrillas/guardar", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function postDetallesBitacoraCuadrilla(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "bitacoracuadrillas/detalles", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
export async function postDetallesBitacoraCuadrillaUpdate(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "bitacoracuadrillas/detallesguardar", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
/** Bitacora cuadrillas */

/** Bitacora traslado */
export async function postCatalogoBitacoraTraslado(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "bitacoratraslados/catalogo", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function postBitacoraTrasladoGuardar(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "bitacoratraslados/guardar", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function postDetallesBitacoraTraslados(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "bitacoratraslados/detalles", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function postDetallesBitacoraTrasladoGuardar(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "bitacoratraslados/detallesguardar", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

/** Bitacora traslado */

/** Bitacora traslado recepción de fruta */
export async function postCatalogoTrasladoRecepcion(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "trasladosrecepcion/catalogo", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function postCatalogoTrasladoRecepcionGuardar(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "trasladosrecepcion/guardar", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function postCatalogoTrasladoRecepcionEliminar(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "trasladosrecepcion/eliminar", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function postGuardarTicketRecepcionFruta(formdata) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "trasladosrecepcion/catalogo/ticket", 'post', formdata);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function postGuardarComprobanteRecepcionFruta(formdata) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "trasladosrecepcion/catalogo/comprobante", 'post', formdata);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

/** Bitacora traslado recepción de fruta */



/*/////////////////////// catálogo tipo de requisición de sericios //////////////////////////////*/

export async function getCatalogoTipoRequisicionServicios() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogotipo/requisiciones-servicios/catalogo", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}



export async function getListaTipoRequisicionServiciosUpdate(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogotipo/requisiciones-servicios/guardar", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function getListaTipoRequisicionServicios() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogotipo/requisiciones-servicios/lista", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

/*/////////////////////// fin catálogo tipo de requisición de sericios //////////////////////////////*/

/*/////////////////////// catálogo tipo destino sericios //////////////////////////////*/

export async function getListaTipoDestinoServicio() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogotipo/destino-servicio/lista", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
/*/////////////////////// fin catálogo tipo destino sericios //////////////////////////////*/


/*/////////////////////// requisiciones de sericios //////////////////////////////*/

export async function getCatalogoRequisicionesServicios() {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/requisiciones-servicios/catalogo", 'get');
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function guardarNuevaRequisicionServicios(json) {

    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/requisiciones-servicios/guardar", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getInformacionRequisicionServicios(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/requisiciones-servicios/informacion", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getRequisicionesServiciosUpdate(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/requisiciones-servicios/estatus", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaValesCombustible(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/compracombustible/vales/lista", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}



/*///////////////////////////almacén equipo info lista //////////////////// */
export async function getAlmacenesEquiposInformaciónLista(idcatalogoalmacen) {
    let jsonfile = {
        "idcatalogoalmacenequipo": idcatalogoalmacen,
    };
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "catalogos/almacenequipo/informacion/lista", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

/**Inicio de bitacora catálogo corrida de limón */
export async function getCatalogoCorridaLimon(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "corridas-limon/catalogo", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function postGuardarCorridaLimon(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "corridas-limon/guardar", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getDetallesCorridaLimon(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "corridas-limon/detalles", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function postGuardarCorridaLimonTraslado(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "corridas-limon/guardar-traslados", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function postGuardarCorridaLimonDetalles(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "corridas-limon/guardar-detalles", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaTrasladosRecepcionDisponibles(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "trasladosrecepcion/lista-disponibles-venta", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function postAutorizarVentaCorridaLimon(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "corridas-limon/finalizar", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

/** Fin de bitacora catálogo corrida de limón */

/* Bitácora catálogo de cotización de naranja */
export async function getCatalogoCotizacionNaranja(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "cotizacion-naranja/catalogo", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function postGuardarCatalogoCotizacionNaranja(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "cotizacion-naranja/guardar", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getDetallesCotizacionNaranja(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "cotizacion-naranja/detalles", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function postGuardarCotizacionNaranjaTraslado(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "cotizacion-naranja/guardar-traslados", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function postGuardarCotizacionNaranjaDetalles(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "cotizacion-naranja/guardar-detalles", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function postAutorizarVentaCotizacionNaranja(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "cotizacion-naranja/finalizar", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
/* Fin de bitácora catálogo de cotización de naranja */

/* Bitácora catálogo de cotización de toronja */
export async function getCatalogoCotizacionToronja(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "cotizacion-toronja/catalogo", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function postGuardarCatalogoCotizacionToronja(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "cotizacion-toronja/guardar", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getDetallesCotizacionToronja(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "cotizacion-toronja/detalles", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function postGuardarCotizacionToronjaTraslado(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "cotizacion-toronja/guardar-traslados", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function postGuardarCotizacionToronjaDetalles(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "cotizacion-toronja/guardar-detalles", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function postAutorizarVentaCotizacionToronja(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "cotizacion-toronja/finalizar", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
/* Fin de bitácora catálogo de cotización de toronjas */

/* Bitácora catálogo de ventas */
export async function getCatalogoVentas(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "ventas/catalogo", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getDetallesVenta(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "ventas/detalle", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function postGuardarPagoVenta(formdata) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "ventas/guardar-pago",'post', formdata, "formdata");
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function postEliminarPagoVenta(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "ventas/eliminar-pago", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function postGuardarFacturaVenta(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "ventas/guardar-factura", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function postEliminarFacturaVenta(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "ventas/eliminar-factura", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getDetallesVentaPagoGlobal(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "ventas/obtener-detalle-pago-global", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function buscarVentasMismoCliente(jsonfile) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "ventas/buscar-ventas-con-mismo-cliente", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }

    return resp;
}

export async function buscarFacturasVentaSeleccionadaPagoGlobal(jsonfile) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "ventas/buscar-facturas-venta", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function guardarPagoGlobalVentas(jsonfile) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "ventas/agregar-facturas-pago-global", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }

    return resp;
}

//guarda pago boton agregar pago en seccion pago global
export async function guardarPagoPagoGlobalVentas(formdata) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "ventas/guardar-pago-global", 'post', formdata, "formdata");
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

//elimina toda la seccion de pago global en modal detalles
export async function eliminarPagoGlobalGeneralVentas(jsonfile) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "ventas/eliminar-pago-global-general", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }

    return resp;
}

//elimina pago en la tabla de pagos en la seccion de pago global
export async function eliminarPagoPagoGlobalVentas(jsonfile) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "ventas/eliminar-pago-global", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }

    return resp;
}

/* Fin de bitácora catálogo de ventas */

/*///////////////////////////informacion cotizacion de servicios  //////////////////// */
export async function getInformacionCotizacionServicios(idrequisicion) {
    var resp = [];
    let jsonfile = {
        "idcatalogo_requisicion": idrequisicion
    };
    try {
        var datos = await consumirApi(apigeneral + "catalogos/cotizaciones-servicios/informacion-cotizacion", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function guardarCotizacionServicios(jsonfile) {
    var resp = [];

    try {
        var datos = await consumirApi(apigeneral + "catalogos/cotizaciones-servicios/guardar-cotizacion", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function cambiarStatusCotizacionServicios(jsonfile) {
    var resp = [];

    try {
        var datos = await consumirApi(apigeneral + "catalogos/cotizaciones-servicios/cambiarestatus", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function comprarCotizacionServicio(idcotizacion) {
    var resp = [];
    let jsonfile = {
        "idcotizacion_proveedor": idcotizacion
    };
    try {
        var datos = await consumirApi(apigeneral + "catalogos/cotizaciones-servicios/comprar", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
/*///////////////////////////fin informacion cotizacion de servicios  //////////////////// */



/*/////////////////////////// inicio catálogos nómina seguridad ////////////////////////////////*/
export async function getCatalogoNominaSeguridad() {

    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "nominas-seguridad/catalogo-nomina", 'get');
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
export async function finalizanominaSeguridad(jsonfile) {

    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "nominas-seguridad/finalizar-nomina", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
export async function guardardatosNominaSeguridad(jsonfile) {

    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "nominas-seguridad/nomina-totales", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function getListaNominaSeguridad(jsonfile) {

    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "nominas-seguridad/nomina", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}

export async function createNuevaNominaSeguridad(jsonfile) {

    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "nominas-seguridad/nueva-nomina", 'post', jsonfile);
        resp = datos;
    } catch (error) {
        //console.log("error de conexion");
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}


export async function GetinfocsvSeguridad(json) {
    var resp = [];
    try {
        var datos = await consumirApi(apigeneral + "nominas-seguridad/nomina-dump", 'post', json);
        resp = datos;
    } catch (error) {
        resp.push({
            code: 1000,
            message: "Error de conexión, revisa tu conexión a internet"
        })
    }
    return resp;
}
